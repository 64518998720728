/* src/index.css */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  font-family: "Noto Sans KR", sans-serif;
  color: #2c374b;
}

.skeleton-box {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row; /* or column */
}

@keyframes moveAndFade {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%); /* 요소의 너비만큼 왼쪽으로 이동 */
    opacity: 1;
  }
}

.animate {
  animation: moveAndFade linear forwards;
}

.paused {
  animation-play-state: paused;
}
.circular-progress {
  position: relative;
  width: 1000px;
  height: 1000px;
  overflow: visible;
}

.bg,
.progress {
  fill: none;
  stroke-width: 10;
  transform-origin: center;
  transform: rotate(-90deg); /* 원의 시작점을 위쪽 12시 방향으로 변경 */
}

.bg {
  stroke: #f1f1f1;
}

.progress {
  stroke: #2ea7e0;
  stroke-dasharray: 301;
  stroke-dashoffset: 301;
  animation: progress 10s forwards linear;
}

@keyframes progress {
  to {
    stroke-dashoffset: 0;
  }
}
